import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

export const required = value => (value ? undefined : 'required');

export const requiredCardNumber = value =>
  value ? undefined : 'Card number is required';

export const emailValid = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const onlyNumber = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  return onlyNums;
};

export const minLength = min => value =>
  value && value.length < min ? `Harus ${min} karakter atau lebih` : undefined;

export const maxLength = max => value =>
  value && value.length > max
    ? `Tidak boleh melebihi ${max} karakter`
    : undefined;

export const minBaNumber = value =>
  value && value.length < 16 ? `Harus 13 angka` : undefined;
export const minNIKNumber = value =>
  value && value.length < 19 ? `Harus 16 karakter atau lebih` : undefined;

export function formatBytes(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return 0;
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Hanya karakter huruf dan angka'
    : undefined;

export const mustAlphaNumeric = value =>
  value && /^[a-z0-9_-]+$/i.test(value)
    ? 'Minimal harus karakter huruf dan angka'
    : undefined;

export const InputField = ({
  input,
  id,
  type,
  label,
  noLabel,
  requiredStar,
  placeholder,
  disabled,
  autoComplete,
  maxLength,
  stylePhone,
  hidden,
  customError,
  error: passedError,
  meta: { touched, error, warning } = {},
  register = {}
}) => {
  const phone = {
    borderRadius: '0px 4px 4px 0px'
  };
  const btnPhone = {
    borderRadius: ' 4px 0px 0px 4px',
    backgroundColor: '#e1e1e1'
  };

  return (
    <div className='form-group' style={hidden ? { display: 'none' } : {}}>
      {!noLabel && (
        <label htmlFor={id} className='font-weight-semibold'>
          {label}
          {requiredStar && <span className='form-error'> *</span>}
        </label>
      )}
      <div className='d-flex'>
        {stylePhone ? (
          <button className='btn ' style={btnPhone}>
            +62
          </button>
        ) : null}
        <input
          {...input}
          id={id}
          type={type}
          placeholder={placeholder}
          className={`form-control ${touched && error ? 'focus-error' : ''}`}
          disabled={disabled}
          autoComplete={autoComplete}
          maxLength={maxLength}
          style={stylePhone ? phone : null}
          {...register}
        />
      </div>
      {touched &&
        ((error && <span className='form-error'>{error}</span>) ||
          (warning && <span className='form-error'>{warning}</span>))}
      {touched && (error || warning) && <br />}
      {customError && <span className='form-error'>{customError}</span>}
      {passedError && <span className='form-error'>{passedError}</span>}
    </div>
  );
};

export const TextAreaField = props => {
  const {
    input,
    id,
    label,
    noLabel,
    requiredStar,
    placeholder,
    rows,
    disabled,
    meta: { touched, error, warning }
  } = props;

  return (
    <div className='form-group'>
      {!noLabel && (
        <label htmlFor={id} className='font-weight-semibold'>
          {label}
          {requiredStar && <span className='form-error'> *</span>}
        </label>
      )}
      <textarea
        {...input}
        placeholder={placeholder}
        rows={rows}
        className={`form-control ${touched && error ? 'focus-error' : ''}`}
        disabled={disabled}
      />
      {touched &&
        ((error && <span className='form-error'>{error}</span>) ||
          (warning && <span className='form-error'>{warning}</span>))}
    </div>
  );
};

export const SelectField = props => {
  const {
    input,
    id,
    label,
    noLabel,
    requiredStar,
    options,
    placeholder,
    autoComplete,
    multiple,
    clearable,
    searchable,
    disabled,
    meta: { touched, error, warning }
  } = props;

  const initValue = () => {
    if (input.value) {
      if (typeof input.value === 'object') {
        return options.find(option => option.value === input.value.value);
      }
      return options.find(option => option.value === input.value);
    }
    return null;
  };

  return (
    <div className='form-group'>
      {!noLabel && (
        <label htmlFor={id} className='font-weight-semibold'>
          {label}
          {requiredStar && <span className='form-error'> *</span>}
        </label>
      )}
      <Select
        name={input.name}
        className={`select-field ${touched && error ? 'focus-error' : ''}`}
        classNamePrefix='select'
        options={options}
        value={initValue()}
        onChange={input.onChange}
        placeholder={placeholder}
        autoComplete={autoComplete}
        isMulti={multiple}
        isClearable={clearable}
        isSearchable={searchable}
        isDisabled={disabled}
      />
      {touched &&
        ((error && <span className='form-error'>{error}</span>) ||
          (warning && <span className='form-error'>{warning}</span>))}
    </div>
  );
};

export const RadioField = ({
  input,
  label,
  id,
  options,
  noLabel,
  required,
  requiredStar,
  disabled,
  meta: { touched, error, warning }
}) => (
  <>
    <div className='form-group'>
      {!noLabel && (
        <label htmlFor={id} className='font-weight-semibold'>
          {label}
          {requiredStar && <span className='form-error'> *</span>}
        </label>
      )}
      <div className=''>
        {options?.map(opt => (
          <label
            key={opt.value}
            className='mr-5 d-flex align-items-center'
            stylse={{ marginBottom: '0px', cursor: 'pointer' }}
          >
            <input
              {...input}
              type='radio'
              value={opt.value}
              checked={opt.value.toString() === input.value}
              required={required}
              disabled={disabled}
            />
            <span className='custom-radio-label ml-3'>{opt.name}</span>
          </label>
        ))}
      </div>
      {touched &&
        ((error && <span className='form-error d-block'>{error}</span>) ||
          (warning && <span className='form-error d-block'>{warning}</span>))}
    </div>
  </>
);

export const RadioBarField = ({
  input,
  label,
  id,
  options,
  noLabel,
  requiredStar,
  disabled,
  error: passedError,
  meta: { touched, error, warning } = {}
}) => {
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    if (input.value) {
      setInputValue(input.value);
    }
  }, [input.value]);

  return (
    <div className='form-group'>
      {!noLabel && (
        <label htmlFor={id} className='font-weight-semibold'>
          {label}
          {requiredStar && <span className='form-error'> *</span>}
        </label>
      )}
      <div>
        <ButtonGroup className='w-100'>
          {options?.map(opt => (
            <Button
              color='outline-primary'
              style={{ width: `calc(100% / ${options.length})` }}
              onClick={() => input.onChange(opt.value)}
              active={inputValue === opt.value}
              disabled={disabled}
            >
              {opt.label}
            </Button>
          ))}
        </ButtonGroup>
      </div>
      {touched &&
        ((error && <span className='form-error d-block'>{error}</span>) ||
          (warning && <span className='form-error d-block'>{warning}</span>))}
      {passedError && <span className='form-error d-block'>{passedError}</span>}
    </div>
  );
};

export const CheckboxField = ({
  input,
  label,
  id,
  className,
  required,
  disabled,
  meta: { touched, error, warning }
}) => (
  <div className='form-group'>
    <label htmlFor={id} className={className}>
      <input
        {...input}
        id={id}
        type='checkbox'
        checked={input.value}
        required={required}
        disabled={disabled}
      />
      <span
        className='custom-checkbox-label'
        style={{ paddingLeft: '2%', textAlign: 'justify' }}
      >
        {label}
      </span>
    </label>
    {touched &&
      ((error && <p className='form-error m-0'>{error}</p>) ||
        (warning && <p className='form-error m-0'>{warning}</p>))}
  </div>
);

export const MultipleCheckboxField = ({
  input,
  label,
  id,
  className,
  required,
  disabled,
  options,
  meta: { touched, error, warning }
}) => (
  <>
    <label htmlFor={id} className={className}>
      {options?.map((opt, index) => (
        <div className='d-flex align-items-center'>
          <input
            type='checkbox'
            name={`${input.name}[${index}]`}
            value={opt.content}
            // checked={option.answerQuestionId.length !== 0}
            checked={input.value.indexOf(opt.value) !== -1}
            required={required}
            disabled={disabled}
            onChange={event => {
              const newValue = [...input.value];
              if (event.target.checked) {
                newValue.push(opt.value);
              } else {
                newValue.splice(newValue.indexOf(opt.value), 1);
              }

              return input.onChange(newValue);
            }}
          />
          <div className='custom-checkbox' />
          <span className='ml-3 custom-checkbox-label'>{opt.name}</span>
        </div>
      ))}
    </label>
    {touched &&
      ((error && <p className='form-error m-0'>{error}</p>) ||
        (warning && <p className='form-error m-0'>{warning}</p>))}
  </>
);

export const DateMonthYearField = ({
  input,
  id,
  label,
  placeholder,
  requiredStar,
  meta: { touched, error, warning }
}) => {
  const ExampleCustomInput = ({ value, onClick }) => {
    let text = 'dd-mm-yyyy';
    if (value !== '') {
      text = value;
    } else if (placeholder) {
      text = <span className='text-muted'>{placeholder}</span>;
    }

    return (
      <button className='example-custom-input' type='button' onClick={onClick}>
        {text}
      </button>
    );
  };

  return (
    <div className='form-group'>
      {label && (
        <label htmlFor={id} className='font-weight-semibold'>
          {label}
          {requiredStar && <span style={{ color: '#dc3545' }}>*</span>}
        </label>
      )}
      <div className={`input-group ${touched && error && 'focus-error'}`}>
        <DatePicker
          {...input}
          selected={input.value ? moment(input.value).valueOf() : null}
          customInput={<ExampleCustomInput />}
          dateFormat='DD-MMMM-yyyy'
          showDateMonthYearPicker
        />
        <div className='input-group-append h-100'>
          <div className='d-flex align-items-center mr-2'>
            <img src='/images/icon/calendar.svg' width='16' alt='' />{' '}
          </div>
        </div>
      </div>
      {touched &&
        ((error && <span className='form-error'>{error}</span>) ||
          (warning && <span className='form-error'>{warning}</span>))}
    </div>
  );
};

export const RenderFieldUpload = props => {
  const {
    input,
    id,
    type,
    label,
    noLabel,
    requiredStar,
    placeholder,
    disabled,
    autoComplete,
    maxLength,
    stylePhone,
    hidden,
    meta: { touched, error, warning }
  } = props;

  const phone = {
    borderRadius: '0px 4px 4px 0px'
  };
  const btnPhone = {
    borderRadius: ' 4px 0px 0px 4px',
    backgroundColor: '#e1e1e1'
  };

  return (
    <div className='form-group' style={hidden ? { display: 'none' } : {}}>
      {!noLabel && (
        <label htmlFor={id} className='font-weight-semibold'>
          {label}
          {requiredStar && <span className='form-error'> *</span>}
        </label>
      )}
      <div className='input-group' style={{ borderRadius: '5px' }}>
        <input
          {...input}
          id={id}
          type={type}
          placeholder={placeholder}
          className={`form-control ${touched && error ? 'focus-error' : ''}`}
          disabled={disabled}
          autoComplete={autoComplete}
          maxLength={maxLength}
          style={stylePhone ? phone : null}
        />
        <div
          className='input-group-append h-100'
          style={{ backgroundColor: '#4573FF' }}
        >
          <div className='d-flex align-items-center mr-2'>
            <button type='button' className='btn p-0 m-0'>
              <span className='font-weight-semibold' style={{ color: 'white' }}>
                Upload
              </span>
            </button>
          </div>
        </div>
      </div>
      {touched &&
        ((error && <span className='form-error'>{error}</span>) ||
          (warning && <span className='form-error'>{warning}</span>))}
    </div>
  );
};

export const RenderFieldMonthYear = ({
  input,
  id,
  label,
  classLabel,
  requiredStar,
  meta: { touched, error, warning }
}) => {
  const ExampleCustomInput = ({ value, onClick }) => (
    <button className='example-custom-input' type='button' onClick={onClick}>
      {value !== '' ? value : 'mm/yyyy'}
    </button>
  );
  return (
    <div className='form-group'>
      {label && (
        <label htmlFor={id} className={classLabel}>
          {label}
          {requiredStar && <span style={{ color: '#dc3545' }}>*</span>}
        </label>
      )}
      <div className={`input-group ${touched && error && 'focus-error'}`}>
        <DatePicker
          {...input}
          selected={input.value ? moment(input.value).valueOf() : null}
          customInput={<ExampleCustomInput />}
          dateFormat='MM/yyyy'
          showMonthYearPicker
        />
        <div className='input-group-append h-100 '>
          <div className='d-flex align-items-center mr-2'>
            <i className='fas fa-calendar-alt' />
          </div>
        </div>
      </div>
      {touched &&
        ((error && <span className='form-error'>{error}</span>) ||
          (warning && <span className='form-error'>{warning}</span>))}
    </div>
  );
};

export const CurrencyField = props => {
  const {
    input,
    id,
    label,
    noLabel,
    requiredStar,
    disabled,
    placeholder,
    errLimit,
    meta: { touched, error, warning }
  } = props;

  return (
    <div className='form-group'>
      {!noLabel && (
        <label htmlFor={id} className='font-weight-semibold'>
          {label}
          {requiredStar && <span className='form-error'> *</span>}
        </label>
      )}
      <NumberFormat
        {...input}
        id={id}
        className={`form-control ${touched && error ? 'focus-error' : ''}`}
        placeholder={placeholder}
        thousandSeparator
        maxLength={19}
        disabled={disabled}
      />
      {errLimit === 'request_limit_must_be_greater_than_zero' && (
        <small className='d-block' style={{ color: '#dc3545' }}>
          Request limit must be greater than zero
        </small>
      )}
      {errLimit === 'request_limit_must_be_greater_than_current_limit' && (
        <small className='d-block' style={{ color: '#dc3545' }}>
          Request limit must be greater than current limit
        </small>
      )}
      {errLimit === 'request_limit_exceeds_max_request_rule' && (
        <small className='d-block' style={{ color: '#dc3545' }}>
          Request limit exceeds max request rule
        </small>
      )}
      {touched &&
        ((error && <span className='form-error'>{error}</span>) ||
          (warning && <span className='form-error'>{warning}</span>))}
    </div>
  );
};
export const DatePickerField = props => {
  const {
    input,
    id,
    label,
    noLabel,
    requiredStar,
    placeholder,
    disabled,
    hidden,
    meta: { touched, error, warning }
  } = props;

  return (
    <div className='form-group' style={hidden ? { display: 'none' } : {}}>
      {!noLabel && (
        <label htmlFor={id} className='font-weight-semibold'>
          {label}
          {requiredStar && <span className='form-error'> *</span>}
        </label>
      )}
      <div className='d-flex'>
        <DatePicker
          {...input}
          id={id}
          placeholderText={placeholder}
          className={`form-control ${touched && error ? 'focus-error' : ''}`}
          disabled={disabled}
        />
      </div>
      {touched &&
        ((error && <span className='form-error'>{error}</span>) ||
          (warning && <span className='form-error'>{warning}</span>))}
    </div>
  );
};
